<template>
    <div>
        <div class="HistorySleep HistorySleepBlock flex" :class="getcolor(items)">
            <div class="HistorySleep__info">
                <div class="HistorySleep__title">
                    {{ $t('history.title_second') }}
                </div>
                <div class="HistorySleep__number">
                    {{ items.sleep_time }}
                    <span> {{ $t('history.hours') }} </span>
                </div>
                <div class="HistorySleep__title">
                    {{ $t('history.title_second_two') }}
                </div>
                <div class="HistorySleep__number">
                    {{ items.sleep_quality }}
                </div>
            </div>

            <CircleSlider v-model="this.currentRate" :read-only="true" />
        </div>
        <div
            v-if="items.awake !== -1 || items.nightmares !== -1 || items.deep_sleep !== -1"
            class="HistorySleepBlock">
            <div v-if="items.awake !== -1" class="HistorySleepBlock__item">
                <div class="HistorySleepBlock__title">{{ $t('history.awakenings') }}</div>
                <div class="HistorySleepBlock__value" :class="items.awake === 1 ? 'bad' : null">
                    {{
                        items.awake === 0
                            ? $t('dialog.confirm.no')
                            : items.awake === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
            <div v-if="items.nightmares !== -1" class="HistorySleepBlock__item">
                <div class="HistorySleepBlock__title">{{ $t('history.nightmares') }}</div>
                <div
                    class="HistorySleepBlock__value"
                    :class="items.nightmares === 1 ? 'bad' : null">
                    {{
                        items.nightmares === 0
                            ? $t('dialog.confirm.no')
                            : items.nightmares === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
            <div v-if="items.deep_sleep !== -1" class="HistorySleepBlock__item">
                <div class="HistorySleepBlock__title">{{ $t('history.deepSleep') }}</div>
                <div class="HistorySleepBlock__value" :class="{ yes: items.deep_sleep }">
                    {{
                        items.deep_sleep === 0
                            ? $t('dialog.confirm.no')
                            : items.deep_sleep === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CircleSlider from '@/components/CircleSlider';

export default {
    name: 'HistorySleep',
    props: {
        items: {
            type: Object,
            default: () => ({}),
        },
        selecId: {
            type: Number,
            default: 0,
        },
    },
    components: {
        CircleSlider,
    },
    data() {
        return {
            rate: 100,
            currentRate: 0,
            gradientColor: {
                '23.91%': '#C6B3A2',
                '67.67%': '#EFE5DC',
                '80.86%': '#B8DEAF',
            },
        };
    },
    mounted() {
        // this.currentRate = (this.items.sleep_time * 10) / 2;
        this.currentRate = this.items.sleep_time;
    },
    computed: {
        getcolor() {
            return (items) => {
                if (items.sleep_time >= 0 && items.sleep_time <= 6) {
                    return 'dark';
                } else if (items.sleep_time >= 7 && items.sleep_time <= 10) {
                    return 'green';
                } else if (items.sleep_time >= 11) {
                    return 'light';
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.HistorySleepBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 12px;
    margin-bottom: 12px;

    &.flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__value {
        display: flex;
        width: fit-content;
        background: #f5eee8;
        border-radius: 7px;
        height: 20px;
        padding: 10px 12px;
        align-items: center;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        &.yes {
            background: #bbd6ad;
        }
        &.bad {
            background: #f28787;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.HistorySleep {
    // padding-bottom: 4px;
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #897a6c;
    }
    &.dark {
        color: #c6b3a2;
    }
    &.green {
        color: #bbd6ad;
    }
    &.light {
        color: #e7dbd0;
    }
    &__number {
        font-style: normal;
        font-weight: 600;
        font-size: 54px;
        line-height: 58px;
        /* or 250% */

        display: flex;
        align-items: baseline;
        letter-spacing: -1px;

        margin-bottom: 3px;
        span {
            font-size: 20px;
            margin-left: 10px;

            margin-left: 2px;
            font-weight: bold;
        }
    }
    &__image {
        margin-right: 13px;
    }
}
.van-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25);
}
</style>
