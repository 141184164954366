<template>
    <div class="mood">
        <div
            class="mood__item"
            v-for="item in aryDates"
            :key="`s-${item}`"
            @click="daySelect(item)">
            <div class="mood__text">{{ getDay(item) }}</div>
            <div class="mood__icon" :class="{ select: date == yyyymmdd(item) }" v-if="isMood(item)">
                <img
                    v-if="activeTabs"
                    :src="require(`@/assets/images/icon-md-mood-0${isMood(item)}.svg`)"
                    alt="..." />
                <img v-else :src="require(`@/assets/images/${isMood(item)}.svg`)" alt="..." />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HistoryMood',
    props: {
        dayList: {
            type: Object,
            default: () => {},
        },
        date: {
            type: String,
            default: '',
        },
        select: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            aryDates: [],
        };
    },
    computed: {
        yyyymmdd() {
            return (item) => {
                var dateString = new Date(item.getTime() - item.getTimezoneOffset() * 60000)
                    .toISOString()
                    .split('T')[0];
                return dateString;
            };
        },
        isMood() {
            return (item) => {
                var dateString = this.yyyymmdd(item);

                let find = this.dayList[dateString];
                if (find) {
                    if (this.activeTabs == 0) {
                        if (find[0].sleep_time >= 0 && find[0].sleep_time <= 6) {
                            return 's-dark';
                        } else if (find[0].sleep_time > 7 && find[0].sleep_time <= 10) {
                            return 's-green';
                        } else if (find[0].sleep_time >= 11) {
                            return 's-light';
                        }
                    }
                    return find[0].mood;
                }
                return false;
            };
        },
        getDay() {
            return (item) => {
                return item.getDate();
            };
        },
        activeTabs() {
            return this.$route.query.selectId;
        },
    },
    methods: {
        getDataList(startDate, daysToAdd) {
            for (var i = 0; i <= daysToAdd; i++) {
                var currentDate = new Date();
                currentDate.setDate(startDate.getDate() + i);
                currentDate.setMonth(startDate.getMonth());
                this.aryDates.push(currentDate);
            }
        },
        daySelect(item) {
            var dateString = this.yyyymmdd(item);

            let find = this.dayList[dateString];
            if (find) {
                this.$emit('daySelect', item);
            }
        },
    },
    mounted() {
        this.getDataList(new Date(this.date), 6);
    },
};
</script>

<style lang="scss" scoped>
.mood {
    display: flex;
    justify-content: center;
    margin-left: -14px;
    margin-right: -14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 10px;

    &__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        user-select: none;
    }

    &__text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: flex-end;
        text-align: center;

        color: #897a6c;
        margin-bottom: 4px;
    }
    &__icon {
        width: 31.08px;
        height: 30.87px;
        position: relative;
        opacity: 0.3;
        &.select {
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
