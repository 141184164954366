<template>
  <div class="page">
    <div class="container" v-if="history.length > 0">
      <div class="history__date">
        {{ new Date(selectItem.date).toLocaleDateString() }}
      </div>
      <HistoryMoodNav
        :dayList="dayList"
        :select="selectItem.mood"
        :date="selectItem.date"
        @daySelect="daySelect"
      />
      <div class="history">
        <div class="history__item">
          <div class="history__header">
            <div class="history__back" @click="back">
              <img src="@/assets/images/arrow-left.svg" alt="arrow-left" />
            </div>

            <div
              class="history__nav"
              v-if="history.length > 1"
              :class="{ disable: active == 0 }"
            >
              <div class="history__nav-item" @click="active = active - 1">
                <img
                  src="@/assets/images/light-arrow-left.svg"
                  alt="arrow-left"
                />
              </div>
              <span> {{ this.active + 1 + " запись" }} </span>
              <div class="history__nav-item" @click="active = active + 1">
                <img
                  src="@/assets/images/light-arrow-right.svg"
                  alt="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="history__title">
            {{ $t("history.title_sleep") }}
          </div>
          <van-tabs v-model:active="active" swipeable @click="updateTab">
            <van-tab
              v-for="(item, index) in history"
              :title="index + 1 + ' запись'"
              :key="`${'s-' + index}`"
            >
              <HistorySleep v-if="index === active" :items="item" :id="index" />
              <div class="history__title">
                {{ $t("history.title_mood") }}
              </div>
              <HistoryMood :items="item" :gender="gender" />
            </van-tab>
          </van-tabs>
        </div>
      </div>

      <div class="history-controll">
        <p class="history-controll__item delete" @click="deleteItem()">
          <img src="@/assets/images/icon/delete.svg" alt="" srcset="" />
          {{ $t("common.delete") }}
        </p>
        <p class="history-controll__item" @click="editItem()">
          <img src="@/assets/images/icon/edit.svg" alt="" srcset="" />
          {{ $t("common.edit") }}
        </p>
      </div>

      <van-button type="primary" class="button" @click="close">
        {{ this.$t("calendar.button2") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HistoryMoodNav from "@/components/HistoryMood.vue";
import HistorySleep from "@/components/HistorySleep.vue";
import HistoryMood from "@/components/HistoryMoodMain.vue";
import ApiCalendar from "@/api/calendar";

export default {
  name: "History",
  data() {
    return {
      numberEntry: 1,
      active: 0,
      keySleep: 0,
      // itemsMood: [1, 2, 3, 4, 5, 6, 7],
      gender: false,
    };
  },
  components: {
    HistoryMood,
    HistoryMoodNav,
    HistorySleep,
  },
  methods: {
    deleteItem() {
      this.$dialog
        .confirm({
          confirmButtonText: this.$t("dialog.confirm.yes"),
          cancelButtonText: this.$t("dialog.confirm.no"),
          title: this.$t("dialog.confirm.delete_title"),
          message: "",
        })
        .then(() => {
          this.$router.push({
            name: "Calendar",
            query: { token: this.$route.query.token },
          });
          ApiCalendar.deleteRecord(this.history[this.active].id).then(() => {});
        })
        .catch(() => {
          // on cancel
        });
    },
    editItem() {
      this.$store.commit("History/set_history", this.history[this.active]);

      this.$router.push({
        name: "New",
        query: { token: this.$route.query.token },
      });
    },
    back() {
      this.$router.push({
        name: "Calendar",
        query: { token: this.$route.query.token },
      });
    },
    close() {
      let tg = window.Telegram.WebApp;
      tg.close();
    },
    updateTab() {
      console.log("tab");
    },
    daySelect(item) {
      let formatData = this.yyyymmdd(item);
      this.$store.commit("History/set_history", this.dayList[formatData]);
    },
  },
  computed: {
    yyyymmdd() {
      return (item) => {
        var dateString = new Date(
          item.getTime() - item.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        return dateString;
      };
    },
    selectItem() {
      return this.history[0];
    },
    activeTabs() {
      return this.$route.query.selectId;
    },
    ...mapGetters("History", ["history"]),
    ...mapGetters("History", ["dayList"]),
  },
  mounted() {
    if (!this.history.length > 0) {
      this.$router.push({
        name: "Calendar",
        query: { token: this.$route.query.token },
      });
    }
  },
};
</script>

<style scoped lang="scss">
.history-controll {
  text-align: center;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #000;
    img {
      margin-right: 5px;
    }
    &.delete {
      color: #e32a2a;
    }
  }
}
.history {
  &__item {
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__nav {
    background: #ffffff;
    border-radius: 7px;
    height: 26px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    img {
      display: block;
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: 600;
      display: flex;
      align-items: center;
      text-align: center;

      color: #897a6d;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__date {
    margin-bottom: 20px;
  }
  &__back {
  }
}
.van-button {
  background: #000000;
  border-color: #000000;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
