<template>
    <div class="HistorySleep__image">
        <img src="@/assets/images/s-green.svg" alt="" v-if="color == 'green'" />
        <img src="@/assets/images/s-light.svg" alt="" v-if="color == 'light'" />
        <!-- <img src="@/assets/images/s-dark.svg" alt="" v-if="color == 'dark'" /> -->

        <div class="circle-slider">
            <round-slider
                v-model="model"
                :value="value"
                :start-angle="90"
                :max="20"
                line-cap="round"
                :readOnly="readOnly"
                :width="20"
                :radius="75"
                pathColor="url(#paint0_linear_346_930)"
                rangeColor="url(#grad1)"
                :showTooltip="false"
                :valueChange="onInput"
                ref="roundSlider"
                :animation="true" />

            <svg
                class="svg-for-color"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                height="0"
                width="0">
                <defs>
                    <linearGradient id="grad1" x1="80%" y1="-10%" x2="128%" y2="50%">
                        <stop offset="0.0157341" stop-color="#C6B3A2" />
                        <stop offset="0.77" stop-color="#EFE5DC" />
                        <stop offset="1" stop-color="#B8DEAF" />
                    </linearGradient>
                </defs>
            </svg>
            <svg
                class="svg-for-color"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                height="0"
                width="0">
                <defs>
                    <linearGradient
                        id="paint0_linear_346_930"
                        x1="129.253"
                        y1="12.454"
                        x2="21.2736"
                        y2="164.528"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#F5EEE8" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
import RoundSlider from 'vue-round-slider';

export default {
    props: {
        value: {
            type: [Boolean, String, Object, Number],
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RoundSlider,
    },
    data() {
        return {
            color: null,
        };
    },
    methods: {
        fixRotateRsBar(e) {
            // const defaultTransform =
            //     this.$refs.roundSlider.$el.childNodes[0].childNodes[1].style.transform;

            // this.$refs.roundSlider.$el.childNodes[0].childNodes[1].childNodes[0].style.transform = `rotate(-${defaultTransform.replace(
            //     /\D/g,
            //     '',
            // )}deg)`;

            // eslint-disable-next-line
            const handle = e.handles ? e.handles[0] : demo3._handleArgs();
            handle.element.addClass('rs-transition').rsRotate(-handle.angle);
        },
        onInput(e) {
            this.$emit('update:modelValue', e.value);

            this.fixRotateRsBar(e);
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        value() {
            if (this.value >= 0 && this.value <= 6) {
                this.color = 'light';
            } else if (this.value >= 7 && this.value <= 10) {
                this.color = 'green';
            } else if (this.value >= 11) {
                this.color = 'light';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.circle-slider {
    border-radius: 50%;
    box-shadow: 0px 2px 14px rgb(0 0 0 / 25%);

    .svg-for-color {
        position: absolute;
        pointer-events: none;
        opacity: 0;
    }
}

.HistorySleep__image {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

    img {
        width: 71.36px;
        height: 70.87px;
        position: absolute;
    }
}
</style>

<style lang="scss">
.circle-slider {
    border-radius: 50%;
    box-shadow: 0px 2px 14px rgb(0 0 0 / 25%);

    .rs-handle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        color: black;
        background: transparent;
        box-shadow: none;

        &::before {
            content: attr(aria-valuenow);
        }
    }
}
</style>
