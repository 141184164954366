<template>
    <div class="HistoryMoodMainWrap">
        <div class="HistoryMoodMainBlock">
            <div class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__info">
                    <div class="HistoryMoodMain__title">
                        {{ $t('history.allState') }}
                    </div>
                    <div
                        class="HistoryMoodMain__title-sub"
                        :style="{ color: getColor(items.mood) }">
                        {{ getTitle(items.mood) }}
                    </div>
                    <div class="HistoryMoodMain__desc">{{ getDesc(items.mood) }}</div>
                </div>
                <div class="HistoryMoodMain__image">
                    <img :src="require(`@/assets/images/icon-md-mood-0${items.mood}.svg`)" />
                </div>
            </div>
        </div>
        <div v-if="showMoodMainBLock" class="HistoryMoodMainBlock">
            <div v-if="items.anxiety !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_1') }}</div>
                <div
                    class="HistoryMoodMain__value"
                    :style="{ background: getBgGood(items.anxiety) }">
                    {{ getValue(items.anxiety) }}
                </div>
            </div>
            <div v-if="items.aggression !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_2') }}</div>
                <div
                    class="HistoryMoodMain__value"
                    :style="{ background: getBgGood(items.aggression) }">
                    {{ getValue(items.aggression) }}
                </div>
            </div>
            <div v-if="items.libido !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_3') }}</div>
                <div class="HistoryMoodMain__value" :style="{ background: getBg(items.libido) }">
                    {{ getValue(items.libido) }}
                </div>
            </div>
            <div v-if="items.sensitivity !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_4') }}</div>
                <div
                    class="HistoryMoodMain__value"
                    :style="{ background: getBg(items.sensitivity) }">
                    {{ getValue(items.sensitivity) }}
                </div>
            </div>
            <div v-if="items.appetite !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_5') }}</div>
                <div class="HistoryMoodMain__value" :style="{ background: getBg(items.appetite) }">
                    {{ getValue(items.appetite) }}
                </div>
            </div>
            <div
                v-if="(items.menstruation && items.menstruation !== -1) || items.menstruation == 0"
                class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_6') }}</div>
                <div
                    class="HistoryMoodMain__value"
                    :style="{ background: getBgTwo(items.menstruation) }">
                    {{
                        items.menstruation === 0
                            ? $t('dialog.confirm.no')
                            : items.menstruation === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
            <div v-if="items.activity !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_7') }}</div>
                <div class="HistoryMoodMain__value" :style="{ background: getBg(items.activity) }">
                    {{ getValue(items.activity) }}
                </div>
            </div>
            <div v-if="items.pleasure !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_8') }}</div>
                <div class="HistoryMoodMain__value" :style="{ background: getBg(items.pleasure) }">
                    {{ getValue(items.pleasure) }}
                </div>
            </div>
            <div v-if="items.meds !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_9') }}</div>
                <div class="HistoryMoodMain__value" :style="{ background: getBgTwo(items.meds) }">
                    {{
                        items.meds === 0
                            ? $t('dialog.confirm.no')
                            : items.meds === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
            <div
                class="HistoryMoodMain__desc item"
                v-if="items.meds && items.meds_comment && items.meds_comment.length > 0">
                {{ items.meds_comment }}
            </div>
            <div v-if="items.pav !== -1" class="HistoryMoodMain__item">
                <div class="HistoryMoodMain__title">{{ $t('history.text_10') }}</div>
                <div
                    class="HistoryMoodMain__value"
                    :style="{ background: items.pav ? '#F28787' : '#F5EEE8' }">
                    {{
                        items.pav === 0
                            ? $t('dialog.confirm.no')
                            : items.pav === 1
                            ? $t('dialog.confirm.yes')
                            : null
                    }}
                </div>
            </div>
            <div
                class="HistoryMoodMain__desc item"
                v-if="items.pav && items.pav_comment && items.pav_comment.length > 0">
                {{ items.pav_comment }}
            </div>
        </div>
        <div class="HistoryMoodMainBlock" v-if="items.comment">
            <div class="HistoryMoodMain__item wrap">
                <div class="HistoryMoodMain__title">{{ $t('history.text_11') }}</div>
                <div class="HistoryMoodMain__text">
                    {{ items.comment }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HistoryMoodMain',
    props: {
        items: {
            type: Object,
            default: () => {},
        },
        gender: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        showMoodMainBLock() {
            if (
                this.items.anxiety !== -1 ||
                this.items.aggression !== -1 ||
                this.items.libido !== -1 ||
                this.items.sensitivity !== -1 ||
                this.items.appetite !== -1 ||
                (this.items.menstruation && this.items.menstruation !== -1) ||
                this.items.menstruation == 0 ||
                this.items.activity !== -1 ||
                this.items.pleasure !== -1 ||
                this.items.meds !== -1 ||
                this.items.pav !== -1
            ) {
                return true;
            } else {
                return false;
            }
        },
        getTitle() {
            return (value) => {
                switch (value) {
                    case 1:
                        return this.$t('mood.picker.item_1.title');
                    case 2:
                        return this.$t('mood.picker.item_2.title');
                    case 3:
                        return this.$t('mood.picker.item_3.title');
                    case 4:
                        return this.$t('mood.picker.item_4.title');
                    case 5:
                        return this.$t('mood.picker.item_5.title');
                    case 6:
                        return this.$t('mood.picker.item_6.title');
                    case 7:
                        return this.$t('mood.picker.item_7.title');
                }
            };
        },
        getDesc() {
            return (value) => {
                switch (value) {
                    case 1:
                        return this.$t('mood.picker.item_1.desc');
                    case 2:
                        return this.$t('mood.picker.item_2.desc');
                    case 3:
                        return this.$t('mood.picker.item_3.desc');
                    case 4:
                        return this.$t('mood.picker.item_4.desc');
                    case 5:
                        return this.$t('mood.picker.item_5.desc');
                    case 6:
                        return this.$t('mood.picker.item_6.desc');
                    case 7:
                        return this.$t('mood.picker.item_7.desc');
                }
            };
        },
        getBg() {
            return (value) => {
                switch (value) {
                    case 0:
                        return '#C6B3A3';
                    case 1:
                        return '#EADFD5';
                    case 2:
                        return '#F5EEE8';
                    case 3:
                        return '#E2D1C3';
                }
            };
        },
        getBgTwo() {
            return (value) => {
                switch (value) {
                    case 0:
                        return '#F5EEE8';
                    case 1:
                        return '#C6B3A3';
                }
            };
        },
        getBgGood() {
            return (value) => {
                switch (value) {
                    case 0:
                        return '#F5EEE8';
                    case 1:
                        return '#EADFD5';
                    case 2:
                        return '#E2D1C3';
                    case 3:
                        return '#C6B3A3';
                }
            };
        },
        getColor() {
            return (value) => {
                switch (value) {
                    case 1:
                        return '#615EFE';
                    case 2:
                        return '#896CFE';
                    case 3:
                        return '#5FAEF8';
                    case 4:
                        return '#96DD94';
                    case 5:
                        return '#F8B147';
                    case 6:
                        return '#FB976C';
                    case 7:
                        return '#F36464';
                }
            };
        },
        getValue() {
            return (items) => {
                if (items == 0) {
                    return this.$t('history.text_13');
                } else if (items == 1) {
                    return this.$t('history.text_14');
                } else if (items == 2) {
                    return this.$t('history.text_15');
                } else if (items == 3) {
                    return this.$t('history.text_16');
                }
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.HistoryMoodMainBlock {
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px 12px;
}
.HistoryMoodMain {
    padding: 28px 14px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .HistoryMoodMain__title {
            max-width: 100%;
        }
        &.wrap {
            flex-direction: column;
            align-items: baseline;
            flex-wrap: wrap;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;

        color: #897a6c;
        max-width: 70%;
        &-sub {
            margin-top: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            /* or 675% */

            display: flex;
            align-items: center;
            letter-spacing: -1px;
        }
    }
    &__desc {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        color: #897a6c;
        max-width: 80%;
        margin-top: 15px;
        &.item {
            margin-top: -12px;
            margin-bottom: 16px;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #7e7e7e;
        margin-top: 15px;
    }
    &__image {
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            max-height: 135px;
            max-width: 135px;
        }
    }
    &__value {
        background: #f5eee8;
        border-radius: 7px;
        padding: 10px 12px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        height: 20px;
        /* identical to box height, or 129% */

        display: flex;
        align-items: center;
        letter-spacing: -0.03em;

        color: #000000;

        &.menstruation {
            background: #c6b3a3;
            border-radius: 7px;
        }
    }
}
</style>
